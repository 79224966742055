<!-- =========================================================================================
  File Name: DashboardAnalytics.vue
  Description: Dashboard Analytics
  ----------------------------------------------------------------------------------------
  Item Name: VueJS Dashboard Admin
  Author:
  Author URL:
========================================================================================== -->

<template>
  <div id="dashboard-analytics">
    <div
      v-if="
        permissionPageAccessArry.includes(
          PermissionVariables.View_Dashboard_module_with_reports
        ) || loginUserRole == 'Admin'
      "
    >
      <div class="vx-row">
        <!-- CARD 1: PROJECTS -->
        <div class="vx-col w-full lg:w-1/3 mb-base">
          <statistics-card-line
            icon="GitMergeIcon"
            statistic="92.6k"
            statisticTitle="PROJECTS"
            :chartData="analyticsData.subscribersGained"
            type="area"
            titleName="PROJECTS"
            deptName="Number of Projects"
            :deptNum="topBarData.projects ? String(topBarData.projects) : '0'"
            :routeName="'/apps/projects'"
          ></statistics-card-line>
        </div>

        <!-- CARD 2: DEPARTMENTS -->
        <div class="vx-col w-full lg:w-1/3 mb-base">
          <statistics-card-line
            icon="HomeIcon"
            statistic="97.5K"
            statisticTitle="DEPARTMENTS"
            :chartData="analyticsData.subscribersGained"
            type="area"
            titleName="DEPARTMENTS"
            deptName="Number of Departments"
            :deptNum="
              topBarData.departments ? String(topBarData.departments) : '0'
            "
            :routeName="'/departmentView'"
          ></statistics-card-line>
        </div>

        <!-- CARD 3: USERS -->
        <div class="vx-col w-full lg:w-1/3 mb-base">
          <statistics-card-line
            icon="UsersIcon"
            statistic="97.5K"
            statisticTitle="USERS"
            :chartData="analyticsData.subscribersGained"
            type="area"
            titleName="USERS"
            deptName="Number of Users"
            :deptNum="topBarData.users ? String(topBarData.users) : '0'"
            :routeName="'/usersView'"
          ></statistics-card-line>
        </div>
      </div>

      <div v-if="projects.length > 0" class="vx-row">
        <!-- TOP FILTER NAVBAR-->
        <div class="vx-col w-full mb-3">
          <div class="filter-nav">
            <div class="section">
              <ul class="list-inline">
                <!-- <li class="list-inline-item font-semibold">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                  <span class="flex cursor-pointer font-semibold">
                    Country
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" class="ml-1"></feather-icon>
                  </span>
                  <vs-dropdown-menu class="w-32">
                    <vs-dropdown-item
                      @click="$emit('timeDurationChanged', 'last-28-days')"
                    >Country 1</vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('timeDurationChanged', 'last-month')">Country 2</vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('timeDurationChanged', 'last-year')">Country 3</vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </li>
              <li class="list-inline-item font-semibold">|</li>
              <li class="list-inline-item font-semibold">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                  <span class="flex cursor-pointer font-semibold">
                    City
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" class="ml-1"></feather-icon>
                  </span>
                  <vs-dropdown-menu class="w-32">
                    <vs-dropdown-item @click="$emit('timeDurationChanged', 'last-28-days')">City 1</vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('timeDurationChanged', 'last-month')">City 2</vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('timeDurationChanged', 'last-year')">City 3</vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </li>
              <li class="list-inline-item font-semibold">|</li>-->
                <li
                  class="list-inline-item font-semibold project-menu-dropdown"
                >
                  <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <span class="flex cursor-pointer font-semibold">
                      Project : {{ selectedProject.project_name }}
                      <feather-icon
                        icon="ChevronDownIcon"
                        svgClasses="h-4 w-4"
                        class="ml-1"
                      ></feather-icon>
                    </span>
                    <vs-dropdown-menu class="w-32">
                      <!-- <vs-dropdown-item
                      @click="$emit('timeDurationChanged', 'last-28-days')"
                    >Project 1</vs-dropdown-item>-->
                      <vs-dropdown-item
                        v-for="(project, projectIndex) in projects"
                        :key="projectIndex"
                        @click="onSelectProject(project)"
                        >{{ project.project_name }}</vs-dropdown-item
                      >
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </li>
              </ul>

              <ul class="list-inline cursor-pointer">
                <li
                  v-on:click="onSelectDate($event, 1, 'toDay')"
                  class="list-inline-item font-semibold"
                  :class="this.todayLine == true ? 'active_line' : ''"
                >
                  Today
                </li>
                <li class="list-inline-item font-semibold">|</li>
                <li
                  v-on:click="onSelectDate($event, -7, '7Days')"
                  class="list-inline-item font-semibold"
                  :class="this.sevenDays == true ? 'active_line' : ''"
                >
                  7 Days
                </li>
                <li class="list-inline-item font-semibold">|</li>
                <li
                  v-on:click="onSelectDate($event, -30, '30Days')"
                  class="list-inline-item font-semibold"
                  :class="this.fullMonth == true ? 'active_line' : ''"
                >
                  1 Month
                </li>
                <li
                  class="list-inline-item font-semibold align-middle datepicker"
                >
                  <!-- :class="isActive ? 'active':''" -->
                  <!-- <datepicker
                  :disabledDates="disabledDates"
                  @selected="onSelectDate($event)"
                  v-model="selectedDate"
                  minimumView="day"
                  maximumView="day"
                ></datepicker>-->
                  <span
                    class="feather-icon select-none relative"
                    v-on:click="myFilter('filterDate')"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-calendar"
                    >
                      <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />
                      <line x1="16" y1="2" x2="16" y2="6" />
                      <line x1="8" y1="2" x2="8" y2="6" />
                      <line x1="3" y1="10" x2="21" y2="10" />
                    </svg>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- CARDS START -->
      <!-- *************** -->
      <div v-if="projects.length > 0" class="vx-row">
        <div class="vx-col w-full md:w-1/2 mb-base">
          <div class="vx-row">
            <div class="vx-col w-full mb-base">
              <!--  STATUS OF PURCHASE ORDERS -->
              <vx-card>
                <h2 class="mb-1 font-bold ml-3 mb-base">
                  Purchase Orders
                  <!-- <change-time-duration-dropdown class="self-end float-right" /> -->
                </h2>
                <div v-if="!chartflag">
                  <!-- CHART -->
                  <vue-apex-charts
                    type="pie"
                    height="345"
                    class="mt-2 mb-12"
                    :options="analyticsData.customersPie.chartOptions"
                    :series="pieChartValues"
                  />

                  <!-- CHART DATA -->
                  <ul class="mb-1">
                    <li
                      v-for="customerData in pieChartData"
                      :key="customerData.name"
                      class="flex justify-between py-3 px-6 border d-theme-border-grey-light border-solid border-r-0 border-l-0 border-b-0"
                    >
                      <span class="flex items-center">
                        <span
                          class="inline-block h-3 w-3 rounded-full mr-2"
                          :class="`bg-${customerData.color}`"
                        ></span>
                        <span class="font-semibold">
                          {{
                            customerData.name.charAt(0).toUpperCase() +
                              customerData.name.slice(1)
                          }}
                        </span>
                      </span>
                      <span>{{ customerData.value }}</span>
                    </li>
                  </ul>
                </div>
                <h5 v-else>No Purchase Orders Available</h5>
                <!-- <vs-divider class="my-4"></vs-divider> -->
                <!-- <div class="pr-6 pl-6 text-right">
                <a href="#">More</a>
              </div>-->
              </vx-card>
            </div>
          </div>
        </div>
        <div class="vx-col w-full md:w-1/2 mb-base">
          <div class="vx-row">
            <div class="vx-col w-full mb-base">
              <vx-card>
                <h2 class="mb-1 font-bold ml-3 mb-base">
                  Budget Report Chart
                  <!-- <change-time-duration-dropdown class="self-end float-right" /> -->
                </h2>
                <div v-if="is_budget">
                  <!-- CHART -->
                  <vue-apex-charts
                    type="pie"
                    height="345"
                    class="mt-2 mb-12"
                    :options="BudgetPie.chartOptions"
                    :series="budgetPieChartValues"
                  />
                  <!-- CHART DATA -->
                </div>
                <h5 v-else>No Budget Report Available</h5>
              </vx-card>
            </div>
          </div>
        </div>

        <div class="vx-col w-full md:w-1/1 mb-base">
          <div class="vx-row">
            <div class="vx-col w-full mb-base">
              <vx-card>
                <h2 class="mb-1 font-bold ml-3 mb-base">
                  Expense Report
                  <!-- <change-time-duration-dropdown class="self-end float-right" /> -->
                </h2>
                <div v-if="pieExpenseChart">
                  <!-- CHART -->
                  <apexchart
                    type="bar"
                    height="350"
                    :options="ExpenseBarChart.chartOptions"
                    :series="ExpenseBarChart.series"
                  ></apexchart>
                  <!-- CHART DATA -->
                </div>
              </vx-card>
            </div>
          </div>
        </div>
        <div class="vx-col w-full md:w-1/1 mb-base">
          <div class="vx-row">
            <div class="vx-col w-full mb-base">
              <vx-card>
                <h2 class="mb-1 font-bold ml-3 mb-base">
                  Expense Amount
                  <!-- <change-time-duration-dropdown class="self-end float-right" /> -->
                </h2>
                <div v-if="pieExpenseChart">
                  <!-- CHART -->
                  <apexchart
                    type="bar"
                    height="350"
                    :options="ExpenseAmountChartOptions"
                    :series="ExpenseAmountSeries"
                  ></apexchart>
                  <!-- CHART DATA -->
                </div>
              </vx-card>
            </div>
          </div>
        </div>
      </div>
      <!-- *************** -->

      <div>
        <vs-prompt
          class="holamundo p-2 dashboard-cal-pop"
          vs-title="Filter PO / Budget Reports"
          :vs-buttons-hidden="true"
          :vs-active.sync="datePickPopup"
        >
          <div class="text-center">
            <div class="w-100 mb-4 mt-2">
              <h5>From Date</h5>
              <datepicker
                placeholder="From Date"
                format="dd-MM-yyyy"
                :disabledDates="startDisabledDates"
                @selected="onSelectDateCal($event, 'from_date')"
                v-model="from_date"
              ></datepicker>
            </div>

            <div class="w-100 mb-4 mt-2">
              <h5>To Date</h5>
              <datepicker
                placeholder="To Date"
                :disabledDates="endDisabledDates"
                v-model="to_date"
                format="dd-MM-yyyy"
                :disabled="toDateDisable"
                @selected="onSelectDateCal($event, 'to_date')"
              ></datepicker>
            </div>

            <!-- SUBMIT BUTTON -->
            <div class="vx-row mt-5">
              <div class="vx-col mx-auto">
                <vs-button
                  color="success"
                  type="filled"
                  class="mr-3"
                  @click="searchFilter"
                  >Search</vs-button
                >

                <vs-button color="danger" @click="privStatus" type="filled"
                  >Cancel</vs-button
                >
                <!-- " -->
              </div>
            </div>
          </div>
        </vs-prompt>
      </div>
    </div>
    <div
      style="margin-top: 30%"
      class="row justify-content-center align-items-center"
      v-else
    >
      <h4>Welcome {{ WelcomeUser }}</h4>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";
import analyticsData from "./ui-elements/card/analyticsData.js";
import ChangeTimeDurationDropdown from "@/components/ChangeTimeDurationDropdown.vue";
import Datepicker from "vuejs-datepicker";
import projectService from "@/services/projectService";
import chartService from "@/services/chartService";
import BudgetService from "@/services/budgetService.js";
import CategoryService from "@/services/categoryService";
import departmentService from "@/services/departmentService";
import UserService from "@/services/UserService";
import { globalPermissionStatus } from "../helper/collection.js";
import PermissionVariables from "@/services/UserManagementVaraibles.js";
import moment from "moment";
import _ from "lodash";
export default {
  beforeMount() {
    // if (!globalPermissionStatus("1000")) {
    //   console.log("access Deniend");
    //   //this.$router.push("/pages/error-500");
    // } else {
    //   console.log("access accepted");
    // }
  },

  data() {
    return {
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      loginUserRole: window.localStorage.getItem("UserRole"),

      PermissionVariables: PermissionVariables,
      startDisabledDates: {
        from: new Date()
      },
      endDisabledDates: {
        from: new Date(),
        to: null
      },
      chartflag: false,
      pieExpenseChart: false,
      projects: [],
      categories: [],
      departments: [],
      pieChartData: [],
      selectedProject: {},
      pieChartValues: [],
      BudgetPieChartValues: [],
      budgetCodes: [],
      budgetPieChartValues: [],
      budgetPieChartData: [],
      is_budget: null,
      datePickPopup: false,
      topBarData: {},
      selectedDate: null,
      // disabledDates: {
      //   from: new Date()
      // },
      // configTodateTimePicker: {
      //   maxDate: new Date(),
      //   dateFormat: "d-m-Y",
      //   minDate: null
      // },
      // configFromdateTimePicker: {
      //   maxDate: new Date(),
      //   dateFormat: "d-m-Y",
      //   minDate: null
      // },
      toDateDisable: true,
      newFilter: null,
      from_date: null,
      to_date: null,
      // users: [],
      isActive: false,
      todayLine: false,
      sevenDays: false,
      fullMonth: false,
      analyticsData: analyticsData,
      isImp: false,
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      show: false,
      nextNum: 10,
      ExpenseAmountSeries: [
        {
          name: "Rejected",
          data: []
        },
        {
          name: "Approved",
          data: []
        },
        {
          name: "Pending",
          data: []
        }
      ],
      ExpenseAmountChartOptions: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true
          // stackType: '100%'
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0
              }
            }
          }
        ],
        xaxis: {
          categories: []
        },

        yaxis: {
          title: {
            text: "Rupees"
          },

          labels: {
            formatter: function(val) {
              return val.toLocaleString("en-IN");

              // override the val here
            }
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return "&#8377 " + val.toLocaleString("en-IN", {});
              //+ "Rupees";
            }
          }
        },
        legend: {
          position: "right",
          offsetX: 0,
          offsetY: 50
        }
      },
      WelcomeUser: window.localStorage.getItem("UserName"),
      ExpenseBarChart: {
        series: [
          {
            name: "Rejected",
            data: []
          },
          {
            name: "Approved",
            data: []
          },
          {
            name: "Pending",
            data: []
          }
        ],
        chartOptions: {
          chart: {
            type: "bar",
            height: 350,
            stacked: true
            // stackType: '100%'
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: "bottom",
                  offsetX: -10,
                  offsetY: 0
                }
              }
            }
          ],
          xaxis: {
            categories: []
          },
          fill: {
            opacity: 1
          },
          legend: {
            position: "right",
            offsetX: 0,
            offsetY: 50
          }
        }
      },
      BudgetPie: {
        series: [],
        chartOptions: {
          labels: [],
          theme: {
            monochrome: {
              enabled: true
            }
          },
          dataLabels: {
            enabled: true
          },
          legend: {
            show: false
          },
          chart: {
            type: "pie",
            offsetY: 30,
            dropShadow: {
              enabled: false,
              blur: 5,
              left: 1,
              top: 1,
              opacity: 0.2
            },
            toolbar: {
              show: false
            }
          },
          stroke: {
            width: 5
          },
          // colors: ["#FFA500", "#00ff00", "#ff0000"],
          fill: {
            type: "gradient",
            gradient: {
              // gradientToColors: ["#FFA500", "#00ff00", "#ff0000"]
            }
          }
        }
      }
    };
  },

  computed: {},
  methods: {
    myFilter: function(type) {
      this.isActive = !this.isActive;
      this.datePickPopup = true;
      if (type == "filterDate") {
        this.todayLine = false;
        this.sevenDays = false;
        this.fullMonth = false;
      }
    },
    onSelectDateCal: function($event, type) {
      if (type == "from_date") {
        this.endDisabledDates["to"] = new Date(
          moment(new Date($event))
            .add(1, "d")
            .format()
        );
        // this.endDisabledDates["to"] = new Date(new Date($event));
        this.dateTo = "";
        this.toDateDisable = false;
      }
    },

    searchFilter: function() {
      this.getPieChartData(this.selectedProject);
      this.getBudgetReportList(this.selectedProject);
      this.datePickPopup = false;
    },
    privStatus: function() {
      this.datePickPopup = false;
    },
    getProjectsList: function() {
      let payload = {};
      projectService
        .getAllProjects(payload)
        .then(response => {
          const { data } = response;
          if (data && data.Status) {
            if (
              !this.permissionPageAccessArry.includes(
                PermissionVariables.ViewOrganizationProjects
              ) &&
              this.loginUserRole != "Admin"
            ) {
              data.data.map(x => {
                if (
                  this.permissionPageAccessArry.includes("3P" + x.project_id)
                ) {
                  this.projects.push(x);
                }
              });
            } else {
              this.projects = data.data;
            }

            //  this.projects = data.data;

            if (this.projects.length > 0) {
              this.selectedProject = this.projects[0];
              this.to_date = moment(new Date())
                .add(1, "days")
                .format("YYYY-MM-DD");
              this.from_date = moment().format("YYYY-MM-DD");
              // this.from_date = moment(new Date())
              //   .add(-30, "days")
              //   .format("YYYY-MM-DD");
              // this.from_date = moment().format("YYYY-MM-DD");
              // this.from_date = moment().format("YYYY-MM-DD");
              // this.to_date = moment().format("YYYY-MM-DD");
              this.getPieChartData(this.projects[0]);
              this.getBudgetReportList(this.projects[0]);
            } else {
              this.to_date = moment(new Date())
                .add(1, "days")
                .format("YYYY-MM-DD");
              this.from_date = moment().format("YYYY-MM-DD");
              this.getPieChartData(null);
              this.getBudgetReportList(null);
            }
          } else {
            vueMain.$vs.notify({
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning"
            });
          }
        })
        .catch(error => {
          // this.$vs.notify({
          //   title: "modal 2",
          //   text: error.Message,
          //   iconPack: "feather",
          //   icon: "check_box",
          //   color: "warning"
          // });
        });
    },
    getPieChartData: function(project) {
      this.pieExpenseChart = false;
      let project_id = project ? project.project_id : 0;
      let payload = {
        from_date: (this.from_date = moment(this.from_date).format(
          "YYYY-MM-DD"
        )),
        to_date: (this.to_date = moment(this.to_date).format("YYYY-MM-DD"))
      };
      console.log(
        "payload :>>payloadpayloadpayloadpayloadpayloadpayload ",
        payload
      );
      chartService
        .pieChartData(project_id, payload)
        .then(response => {
          const { data } = response;
          if (data.Status) {
            this.$vs.loading.close();
            this.topBarData = data.orgCommon;
            let pieChartObj = data.purchaseStat;
            let colors = ["#7961F9", "#FF9F43", "#EA5455"];

            this.pieChartValues = [];
            this.pieChartData = [];
            this.pieChartData = Object.keys(pieChartObj).map(i => {
              let obj = {
                name: i,
                value: pieChartObj[i],
                color: colors[i]
              };
              return obj;
            });

            this.pieChartValues = this.pieChartData.map(x => {
              return x.value;
            });

            let total = 0;
            this.pieChartValues.map(x => {
              total = total + parseInt(x);
            });

            if (total <= 0) {
              this.chartflag = true;
            } else {
              this.chartflag = false;
            }
            let expenseDashNew = data.expenseDash;
            this.ExpenseBarChart.series[0].data = [];
            this.ExpenseBarChart.series[1].data = [];
            this.ExpenseBarChart.series[2].data = [];
            this.ExpenseAmountSeries[0].data = [];
            this.ExpenseAmountSeries[1].data = [];
            this.ExpenseAmountSeries[2].data = [];
            this.ExpenseBarChart.chartOptions.xaxis.categories = [];
            this.ExpenseAmountChartOptions.xaxis.categories = [];
            data.arrayDate.reverse().map(x => {
              let newCheck = expenseDashNew.filter(y => {
                return y.created_date_new == x.date_new;
              });

              let approved_count = 0;
              let approved_sum = 0;
              let pending_count = 0;
              let pending_sum = 0;
              let rejected_count = 0;
              let rejected_sum = 0;
              let array_new = [];
              if (newCheck.length > 0) {
                array_new = newCheck[0].expense.split(",");
                array_new.map(z => {
                  let array_final = z.split("--")[1];
                  let array_sum = z.split("--")[2];
                  if (array_final == 1) {
                    approved_count++;
                    approved_sum = approved_sum + parseInt(array_sum);
                  } else if (array_final == 2) {
                    rejected_count++;
                    rejected_sum = rejected_sum + parseInt(array_sum);
                  } else {
                    pending_count++;
                    pending_sum = pending_sum + parseInt(array_sum);
                  }
                });
              }
              this.ExpenseBarChart.chartOptions.xaxis.categories.push(
                x.format_date + `(${array_new.length})`
              );
              this.ExpenseAmountChartOptions.xaxis.categories.push(
                x.format_date + `(${array_new.length})`
              );

              this.ExpenseBarChart.series[0].data.push(rejected_count);
              this.ExpenseBarChart.series[1].data.push(approved_count);
              this.ExpenseBarChart.series[2].data.push(pending_count);
              this.ExpenseAmountSeries[0].data.push(rejected_sum);
              this.ExpenseAmountSeries[1].data.push(approved_sum);
              this.ExpenseAmountSeries[2].data.push(pending_sum);
            });

            setTimeout(x => {
              this.pieExpenseChart = true;
            }, 300);
          } else {
            vueMain.$vs.notify({
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "error"
            });
          }
        })
        .catch(err => {
          vueMain.$vs.notify({
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "error"
          });
        });
    },

    getBudgetReportList: function(project) {
      let payload = {
        from_date: (this.from_date = moment(this.from_date).format(
          "YYYY-MM-DD"
        )),
        to_date: (this.to_date = moment(this.to_date).format("YYYY-MM-DD"))
      };
      // this.BudgetPie.chartOptions.labels = [];
      let projectId = project.project_id;
      BudgetService.getBudgetReport(projectId, payload)
        .then(response => {
          const { data } = response;
          if (data["data"] && data.Status) {
            this.$vs.loading.close();

            this.budgetReport = data.data;
            console.log(this.budgetReport, "fdsfdsfbud");

            let filterArry = this.budgetReport.map(x => {
              return x.actual_amount_new;
            });

            this.newFilter = filterArry.filter(x => {
              return x > 0;
            });

            if (this.newFilter.length > 0) {
              this.is_budget = true;
            } else {
              this.is_budget = false;
            }

            this.budgetCodes = this.budgetReport.map(x => {
              return x.budget_code;
            });

            this.budgetPieChartValues = this.budgetReport.map(x => {
              return x.actual_amount_new;
            });

            this.updateChart();
            // this.gridApi.sizeColumnsToFit();
          } else {
          }
        })
        .catch(error => {
          console.log("error :", error);
        });
    },
    updateChart() {
      this.budgetReport.map((x, index) => {
        if (x.actual_amount_new > x.budget_amount_new) {
          // this.BudgetPie.chartOptions.colors[index] = "#ff0000";
          // this.BudgetPie.chartOptions.fill.gradient.gradientToColors[index] =
          //   "ff0000";
        }
      });
      this.budgetCodes.map(x => {
        return this.BudgetPie.chartOptions.labels.push(x);
      });
    },
    onSelectProject: function(project) {
      this.selectedProject = project;
      this.getPieChartData(project);
      this.getBudgetReportList(project);
    },
    onSelectDate: function(event, value, type) {
      if (type == "toDay") {
        this.sevenDays = false;
        this.fullMonth = false;
        this.todayLine = true;
      }
      if (type == "7Days") {
        this.todayLine = false;
        this.fullMonth = false;
        this.sevenDays = true;
      }
      if (type == "30Days") {
        this.todayLine = false;
        this.sevenDays = false;
        this.fullMonth = true;
      }

      this.$vs.loading();
      let numberOfDaysToAdd = value;
      this.to_date = moment()
        .add(1, "d")
        .format("YYYY-MM-DD");
      this.from_date = moment().format("YYYY-MM-DD"); //Today
      if (value) {
        value === 1
          ? (this.from_date = moment(new Date()).format("YYYY-MM-DD"))
          : (this.from_date = moment(new Date())
              .add(numberOfDaysToAdd, "days")
              .format("YYYY-MM-DD"));
        this.selectedDate = this.from_date;
      } else {
        this.selectedDate = event;
        this.from_date = moment(this.selectedDate).format("YYYY-MM-DD");
      }
      this.isActive = false;

      this.getPieChartData(this.selectedProject);
      this.getBudgetReportList(this.selectedProject);
    }
  },
  components: {
    VueApexCharts,
    apexchart: VueApexCharts,
    StatisticsCardLine,
    ChangeTimeDurationDropdown,
    Datepicker
  },
  mounted() {
    this.getProjectsList();
    // this.getCategoryList();
    // this.getDepartmentList();
    // this.getUserList();
  }
};
</script>

<style lang="scss">
#dashboard-analytics {
  .greet-user {
    position: relative;
    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }
}
</style>

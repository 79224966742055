import Api from './Api.js';

const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};

export default {
  pieChartData(id, payload) {
    return Api().post(`/v2/dashboard/${id}?dateFrom=${payload.from_date}&dateTo=${payload.to_date}&organization_id=${organization_id()}`);
  }
}
